.calendar-icon-container {
    margin: 20px auto;
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border-radius: 10px;
    border: #bbbbbb 1px solid;
    position: relative;
    box-shadow: #2d3037 0px 2px 3px;

    &:before {
        content: '';
        display: block;
        width: 70px;
        height: 35px;
        border-radius: 10px 10px 0 0;
    }

    .calendar-icon-header {
        width: 70px;
        height: 20px;

        position: absolute;
        top: -2px;
        left: -1px;

        background-color: #c75c5c;
        border-radius: 10px 10px 0 0;
        border-bottom: 3px solid #e5e5e5;

        font: 400 10px/20px Arial, Helvetica, Geneva, sans-serif;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: #fff;

        text-align: center;
    }

    .calendar-icon-body {
        width: 67px;
        height: 35px;

        position: absolute;
        top: 20px;

        font: 400 36px/40px 'Helvetica Neue', Arial, Helvetica, Geneva,
            sans-serif;
        font-weight: bold;
        letter-spacing: -1px;
        color: #4c566b;
        text-align: center;
        z-index: 10;
    }
}

/* Hide the default end-icon within the date-input field */
input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
